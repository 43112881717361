import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'pipeline-items-filters-card',
  templateUrl: './items-filters-card.component.html',
  styleUrls: ['./items-filters-card.component.scss']
})
export class ItemsFiltersCardComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() filters: any;
  @Input() view: any;

  @Output() filtersChanged = new EventEmitter();

  constructor(
    private sidebar: SidebarService,
  ) {

  }

  doRefresh() {

  }

  filterValueChanged(filter: any, event: any|null = null) {
    this.filtersChanged.emit(this.filters || this.view.filters);
  }

  initFilters() {

    if(!this.filters || !this.filters.length) {
      return false;
    }
    
    this.filters.forEach((filter: any) => {
      filter.dualKnobs = (filter.min !== filter.max);
      filter.value = (!!filter.dualKnobs ? { lower: filter.min, upper: filter.max } : filter.min);
    });
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.initFilters();
  }

  onFilterRangeChanged(filter: any, event: any|null = null) {
    console.log('onFilterRangeChanged: filter', filter);
    console.log('onFilterRangeChanged: event', event);

    this.filtersChanged.emit(this.filters || this.view.filters);
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.filters = this.cards.filters || {};
    this.cards.filters.open = !this.cards.filters.open;

    this.sidebar.setCards(this.cards);
  }

}