import { Injectable } from '@angular/core';

import { AiLoaderService } from './ai-loader.service';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { GetgeniusService } from '../getgenius/getgenius.service';

import { createAction, props } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AiBridgeService {

  CACHE_KEY_CONFIG: string = 'pipeline_ai_worker_config';

  CACHE_KEY_MODELS_CACHE: string = 'pipeline_ai_models_list';

  config: aiSettings = {
    temperature: 0.5,
  };

  constructor(
    private aiLoader: AiLoaderService,

    private AppCMS: AppcmsService,
    private cache: CacheService,
    private getgenius: GetgeniusService,
  ) {

  }

  execute(item: aiExecutionRequest, blForceRefresh: boolean = false, config: any | null = null, params: any = {}) {
    return new Promise(async (resolve, reject) => {

      if (!!config) {
        item.config = config;
      }

      console.log('ai-bridge: execute: config', config);
      console.log('ai-bridge: execute: item', item);
      console.log('ai-bridge: execute: params', params);

      try {

        if (!!config && !!config.provider && (config.provider === 'local')) {
          // if local execution requested, execute using AI loader
          this.executeLocal(item, blForceRefresh, config, params).then(resolve).catch(reject);
        } else {
          // else, execute using server-side request

          // first register ai execution job on the server-side (token + queue)
          await this.getgenius.registerAction({
            config: config,
            item: item,
            params: params,
          });

          // then execute the server-side request
          this.AppCMS.loadPluginData('pipeline', Object.assign((params || {}), {
            item: item,
          }), ['ai', 'execute'], {}, blForceRefresh).then(resolve).catch(reject);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  executeLocal(item: aiExecutionRequest, blForceRefresh: boolean = false, config: any | null = null, params: any = {}) {
    return new Promise(async (resolve, reject) => {
      console.log('executeLocal: item', item);
      console.log('executeLocal: config', config);
      console.log('executeLocal: params', params);

      if (!item || !item.post_content || !item.post_content.length) {
        reject('error_missing_input_content');
      } else {
        try {

          if (!!config && !!config.model) {
            this.aiLoader.setSelectedModel(config.model);
          }

          this.aiLoader.completions(
            item.post_content,
            (item.history || []),
            true,
            {

              // stream partial result (currently only supported in local mode)
              onPartialResult: params.onPartialResult || ((partialResult: string, partIndex: number) => {
                console.log('executeLocal: default: partialResult', partialResult);
              }),

            }
          ).then(resolve).catch(reject);
        } catch (e) {
          reject(e);
        }
      }
    });
  }

  getBasePrompt() {
    return (!!this.config && !!this.config.basePrompt ? this.config.basePrompt : null);
  }

  getConfig() {
    return this.config || {};
  }

  async getPreferredModels(context: string = 'general') {
    const cacheKey: string = `${this.CACHE_KEY_MODELS_CACHE}_${context}`;
    const fromCache: cacheItem = await this.cache.get(cacheKey, -1);

    if (!!fromCache && !!fromCache.data) {
      return fromCache.data;
    }

    return [];
  }

  async initConfig() {
    const fromCache: cacheItem = await this.cache.get(this.CACHE_KEY_CONFIG, -1);

    if (!!fromCache && !!fromCache.data) {
      this.config = Object.assign(this.config, fromCache.data || {});
    }
  }

  setBasePrompt(prompt: string) {
    this.config.basePrompt = prompt;
    return this;
  }

  setConfig(config: aiSettings) {

    if (!!config) {
      this.config = config;

      if (!!config && !!config.models && !!config.models.length) {
        this.syncPreferredModels(config.models, config.context || 'general');
      }

      return this.cache.set(this.CACHE_KEY_CONFIG, this.config);
    }

    return this;
  }

  async setPreferredModels(models: aiModel[], context: string = 'general') {
    const cacheKey: string = `${this.CACHE_KEY_MODELS_CACHE}_${context}`;
    return this.cache.set(cacheKey, (models || [] as aiModel[]));
  }

  async syncPreferredModels(models: aiModel[], context: string = 'general') {
    const existing: aiModel[] = (await this.getPreferredModels(context) || []);

    const existingUids: any[] = existing.map((item: aiModel) => {
      return item.uid;
    });

    let all: aiModel[] = existing;

    if (!!models && !!models.length) {
      models.forEach((model: aiModel) => {
        if (existingUids.indexOf(model.uid) === -1) {
          all.push(model);
        }
      });
    }

    const sorted: aiModel[] = all.sort((a: any, b: any) => {
      const _a: string = `${a.name}`.toLowerCase();
      const _b: string = `${b.name}`.toLowerCase();

      if (_a < _b) return -1;
      if (_b > _a) return 1;
      return 0;
    });

    return this.setPreferredModels(sorted, context);
  }

}