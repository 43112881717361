import { Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { LoadingController } from "@ionic/angular";

import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { AiStorePage } from "../ai-store/ai-store.page";

@Component({
  selector: "app-ai-tools",
  templateUrl: "./ai-tools.page.html",
  styleUrls: ["./ai-tools.page.scss"],
})
export class AiToolsPage implements OnDestroy, OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  cards: any = {
    folders: { open: true },
  };

  executeTool: boolean;

  mode: string | null;

  multiple: boolean;

  post: post | any;

  search: searchOptions = {
    itemsKey: 'commandsList',
    keys: ['title', 'description', 'key', 'name', 'url', 'type', 'uid'],
    query: '',
  };

  state: state = {};

  tools: aiTool[];

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: "ai_tools_top_card",
      text: "ai_tools_top_card_text",
      title: "ai_tools_top_card_title",
    },
    promptType: "user",
    route: "ai/tools",
    selectionOptions: [
      {
        icon: 'trash-outline',
        label: 'delete',
        uid: 'delete',
      },
      {
        icon: 'copy-outline',
        label: 'duplicate',
        uid: 'duplicate',
      }
    ],
    showMenuButton: true,
    showProjectsSelect: false, // @debug changes project in the background, disabled
    title: "ai_tools",
  };

  constructor(
    public aiTools: AiToolsService,
    private configService: ConfigService,
    private events: EventsService,
    private loading: LoadingController,
    private modalService: ModalService,
    private projects: ProjectsService,
    private toolsService: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.colSize = {
      left: this.view.sidebarSize || (!!this.view.isDesktop ? 3 : 12),
      item: (this.view.viewType === 'grid' ? (this.view.isDesktop ? (this.view.expertMode && !this.view.isUltraWide ? 4 : this.view.isUltraWide ? 2 : 3) : 6) : 12),
      right: (!!this.view.isUltraWide ? 10 : (window.innerWidth > 768 ? 9 : 12)),
    };
  }

  async choose(tool: any) {
    tool.key = tool.key || "execute";

    // update post if provided
    if (!!this.post) {
      if (!!this.post.post_content && !!tool.input) {
        this.post.post_content = `${tool.input}: "${this.toolsService.stripHtml(
          this.post.post_content
        )}"`;
      } else
        if ((!this.post.post_content || !this.post.post_content.length) && !!tool.input) {
          this.post.post_content = tool.input;
        }
    }

    if (!this.executeTool) {
      this.dismiss(tool, 'done');
    } else {
      const loading = await this.loading.create();
      loading.present();

      try {
        this.aiTools[tool.key](this.post)
          .then((response: any) => {
            loading.dismiss();
            this.dismiss(response, 'done');
          })
          .catch((error: any) => {
            loading.dismiss();
            this.events.publish("error", error);
          });
      } catch (e) {
        loading.dismiss();
        console.warn("> ai optimization failed", e);
      }
    }
  }

  chooseSelected(event: any|null = null) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    return this.dismiss({
      items: this.view.selectedItems,
    });
  }

  createShortcode(event: any|null = null) {
    this.aiTools
      .createShortcode({
        post: this.post,
      })
      .then((response: any) => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.toolsService.bulk({
      action: 'deleteShortcode',
      items: this.view.selectedItems,
      service: this.aiTools,
      useObjectAsIdentifier: true,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  deleteShortcode(shortcode: aiShortcode) {
    this.aiTools
      .deleteShortcode(shortcode)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  dismiss(data: any|null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh(event: any|null = null) {
    this.loadShortcodes(true)
      .then(() => {

        if (!!event) {
          event.target.complete();
        }

        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish("error", error);
      });
  }

  duplicate(shortcode: aiShortcode) {
    this.aiTools.duplicateShortcode(shortcode)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  duplicateSelected(event: any|null = null) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.toolsService.bulk({
      action: 'duplicateShortcode',
      identifier: 'uid',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
      },
      service: this.aiTools,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  edit(shortcode: aiShortcode) {
    this.aiTools
      .editShortcode(shortcode)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.loadProject();
      this.doRefresh();
    });

  }

  ionViewWillEnter() {
    this.initEvents();
  }

  loadToolsByFolder(event: any|null = null) {
    console.log('loadToolsByFolder: event', event);
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadShortcodes(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.commandsList = [];
        resolve(this.view.commandsList);
      } else {
        this.aiTools
          .getCustomShortcodes(blForceRefresh)
          .then((shortcodes: aiShortcode[]) => {

            delete this.view.commandsList_backup;
            this.view.commandsList = (shortcodes || []).concat(JSON.parse(JSON.stringify(this.tools)) || ([] as any[]));

            resolve(this.view.commandsList);
          })
          .catch(reject);
      }
    });
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.view.promptTypes = this.aiTools.getPromptTypes();

    this.tools = this.aiTools.getTools();
    this.view.commandsList = this.tools;

    this.calcViewVars();

    this.loadShortcodes()
      .catch((error: any) => {
        console.warn("> error", error);
      });
  }

  onFolderLoaded(event: any|null = null) {
    console.log('onFolderLoaded: event', event);
  }

  onItemChecked(tool: any) {

    this.view.selectedItems = this.view.commandsList.filter((_tool: any) => {
      return _tool.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onPromptTypeChanged(promptType: aiPromptType | null = null) {
    console.log('onPromptTypeChanged: promptType', promptType);

    if (!!promptType && !!promptType.uid) {
      this.view.promptType = promptType.uid;
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.calcViewVars();
  }

  onSearchChanged(searchOptions: any|null = null) {
    console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any|null = null) {
    switch (this.view.selectionAction) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }

  async store() {

    const modal: any = await this.modalService.create({
      component: AiStorePage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  runSearch() {
    try {
      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}