import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { FontsService } from 'src/app/services/utils/fonts.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { TemplatesService } from 'src/app/services/media/templates.service';
import { ToolsService } from "src/app/services/utils/tools.service";

@Component({
  selector: 'pipeline-template-settings',
  templateUrl: './template-settings.component.html',
  styleUrls: ['./template-settings.component.scss'],
})
export class TemplateSettingsComponent implements OnInit {
  @Input() cards: any = {};
  @Input() template: mediaTemplate;
  @Input() mini: boolean;
  @Input() view: any;

  @Output() colorChanged = new EventEmitter();
  @Output() templateChanged = new EventEmitter();

  fallbackAvatarImg: string = './assets/img/avatars/1.jpg';
  fallbackImg: string = './assets/img/fallback.webp';

  constructor(
    private events: EventsService,
    private fonts: FontsService,
    private media: MediaextendService,
    private templates: TemplatesService,
    private tools: ToolsService,
  ) {
    this.template = this.template || {};

    this.template.config = this.template.config || {};
    this.template.config.Comp = (this.template.config.Comp || {}) as any;
    this.template.config.Comp.accent = (this.template.config.Comp.accent || {}) as any;
    this.template.config.Comp.bg = (this.template.config.Comp.bg || {}) as any;
    this.template.config.Comp.call2action = (this.template.config.Comp.call2action || {}) as any;
    this.template.config.Comp.call2action_link = (this.template.config.Comp.call2action_link || {}) as any;
    this.template.config.Comp.source = (this.template.config.Comp.source || {}) as any;
    this.template.config.Comp.subtext = (this.template.config.Comp.subtext || {}) as any;
    this.template.config.Comp.title = (this.template.config.Comp.title || {}) as any;

    this.template.config._meta = this.template.config._meta || {};
  }

  _colorChanged(event: any|null = null, parentKey: string, key: string | null = null) {
    let target: any;

    if (!!key) {
      this.template.config.Comp[parentKey][key] = event;
      target = this.template.config.Comp[parentKey][key];
    } else {
      this.template.config.Comp[parentKey] = event;
      target = this.template.config.Comp[parentKey];
    }

    this.updatePreview();

    this.colorChanged.emit({
      event: event,
      key: key,
      parentKey: parentKey,
      target: target,
    });
  }

  attachmentFailed(key: string) {
  }

  chooseMediaFromList(media: mediaItem) {
    if (!!media.thumbnail) {
      this.template.photo = media.thumbnail;

      if (!!this.template.config._meta) {
        this.template.config._meta.bg_src = this.template.photo;
      }
    }
  }

  async load() {
    try {
      if (!!this.template && !!this.template.custom) {
        await this.loadFonts();
      }
    } catch (e) {
      console.warn('loading project data failed', e);
    }
  }

  loadFonts() {
    return new Promise((resolve, reject) => {
      this.fonts.getAll()
        .then((fonts: any) => {
          if (!!fonts && !!fonts.length) {
            this.view.fonts = this.tools.shuffle(fonts).slice(0, 100);
          }
          resolve(fonts);
        })
        .catch(reject);
    });
  }

  ngOnInit() {

    if (!!this.template && !!this.template.config && !!this.template.config._meta && !!this.template.config._meta.media) {
      this.view.mediaList = this.template.config._meta.media;
    }

    this.updatePreview();

    if (!!this.view && !!this.view.cards && !!this.view.cards.fonts) {
      this.load()
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    }
  }

  onAccentColorChanged(event: any|null = null, key: string | null = null) {

    if (!this.view.expertMode) {
      this.template.config.Comp.accent = (this.template.config.Comp.accent || {}) as any;
      this.template.config.Comp.bg = (this.template.config.Comp.bg || {}) as any;

      this.template.config.Comp.call2action = (this.template.config.Comp.call2action || {}) as any;
      this.template.config.Comp.call2action.bg_color = this.template.config.Comp.call2action.bg_color || this.template.config.Comp.accent.color;
      this.template.config.Comp.call2action.bg_color_dark = this.template.config.Comp.call2action.bg_color_dark || this.template.config.Comp.accent.color_dark;

      this.template.config.Comp.call2action_link = (this.template.config.Comp.call2action_link || {}) as any;
      this.template.config.Comp.call2action_link.bg_color = this.template.config.Comp.call2action_link.bg_color || this.template.config.Comp.accent.color;
      this.template.config.Comp.call2action_link.bg_color_dark = this.template.config.Comp.call2action_link.bg_color_dark || this.template.config.Comp.accent.color_dark;

      this.template.config.Comp.logo_bg_color = this.template.config.Comp.logo_bg_color || this.template.config.Comp.accent.color;

      this.template.config.Comp.source = (this.template.config.Comp.source || {}) as any;
      this.template.config.Comp.source.bg_color = this.template.config.Comp.source.bg_color || this.template.config.Comp.accent.color;
      this.template.config.Comp.source.bg_color_dark = this.template.config.Comp.source.bg_color_dark || this.template.config.Comp.accent.color_dark;

      this.template.config.Comp.subtext = (this.template.config.Comp.subtext || {}) as any;
      this.template.config.Comp.subtext.bg_color = this.template.config.Comp.subtext.bg_color || this.template.config.Comp.accent.color;
      this.template.config.Comp.subtext.bg_color_dark = this.template.config.Comp.subtext.bg_color_dark || this.template.config.Comp.accent.color_dark;

      this.template.config.Comp.title = (this.template.config.Comp.title || {}) as any;
      this.template.config.Comp.title.bg_color = this.template.config.Comp.title.bg_color || this.template.config.Comp.accent.color;
      this.template.config.Comp.title.bg_color_dark = this.template.config.Comp.title.bg_color_dark || this.template.config.Comp.accent.color_dark;

      this.updatePreview();
    }

    this.updateVars();
  }

  onAttachmentUrlPasted(key: string) {
    this.events.publish('lottie:refresh', this.template);
  }

  onFontSelected(font: font) {
    if (this.view.fonts && this.view.fonts.length) {
      this.view.selectedFonts = this.view.fonts.filter((_font: font) => {
        return !!_font.checked || (_font.uid === font.uid && !!font.checked);
      });
    } else {
      this.view.selectedFonts = [];
    }
  }

  onLogoBgColorTransparentToggle() {
    if (!!this.view.logo_bg_color_transparent) {
      this.template.config.Comp.logo_bg_color = '#00000000';
    }
  }

  onViewInputChanged(event: any|null = null) {
    this.updatePreview();
    this.updateVars();
  }

  removeMediaFromList(media: mediaItem) {

    this.view.mediaList = this.view.mediaList.filter((_mediaItem: mediaItem) => {
      return _mediaItem.ID !== media.ID;
    });

    this.template.config._meta.media = this.view.mediaList;
  }

  toggleCard(card: string) {
    this.view.card = card;
    this.events.publish('video:template:card:updated', card);
  }

  updatePreview() {
    this.template.composition = this.view.previewComposition;
    this.updateVars();
  }

  updateVars() {
    this.templates.setRootVars(this.template);

    this.events.publish('template:settings:updated', this.template);

    this.templateChanged.emit(this.template);
  }

  uploadAttachment(key: string) {
    this.media.applyFromWeb(null, {
      services: ["upload", "media_library"],
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.template.config._meta[key] = imageUrl;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

}