import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { BasketService } from 'src/app/services/ecommerce/basket.service';
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ViewService } from 'src/app/services/core/view.service';

import { ConfigureProductPage } from '../configure-product/configure-product.page';
import { CouponPage } from 'src/app/pages/extensions/coupons/coupon/coupon.page';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.page.html',
  styleUrls: ['./basket.page.scss'],
})
export class BasketPage implements OnInit {

  basketInfo: basketInfo = {
    hasRequestMinimumValue: false,
  };

  basketStorage: any;

  couponModal: any;

  state: state = {};

  upSellingConfig: any = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    title: 'basket',
  };

  constructor(
    private basket: BasketService,
    private events: EventsService,
    private navCtrl: NavController,
    private modalService: ModalService,
    private viewService: ViewService,
  ) {
  }

  async add(item: basketItem, index: number) {
    if (!item.variations || !item.variations.length) {
      item.amount = parseFloat((item.amount || 1) + '');
      item.amount++;
      this.basket.updateIndex(index, item)
        .then(async () => {
          this.basketInfo = await this.basket.calculateBasketInfo();
          this.basketStorage = await this.basket.getBasket();
        });
    } else {
      this.basket.add(item)
        .then(async () => {
          this.basketInfo = await this.basket.calculateBasketInfo();
          this.basketStorage = await this.basket.getBasket();
        });
    }
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  checkout() {
    this.dismiss(null, 'done');
    this.events.publish('view:payments');
  }

  async configure(product: any) {

    const modal: any = await this.modalService.create({
      component: ConfigureProductPage,
      componentProps: {
        product: product,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
    });

    modal.onWillDismiss().then(response => {
      if (response.data) {
        let updatedProduct = response.data.product || null;
        this.basketStorage.forEach((basketItem: any, index: number) => {
          if (basketItem.id === product.id) {
            this.basketStorage[index] = updatedProduct;
          }
        });
        this.basket.setBasket(this.basketStorage);
      }
    });


    this.modalService.present(modal);
  }

  async coupon() {

    this.couponModal = await this.modalService.create({
      component: CouponPage,
      animated: true,
    });

    this.couponModal.onWillDismiss()
      .then(
        (response: any) => this.validateCoupon(response)
      );

    this.modalService.present(this.couponModal);
  }

  async dismiss(data: any|null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  ionViewWillEnter() {
    this.loadBasket();
  }

  loadBasket() {
    this.basket.getBasket()
      .then(async (basketStorage: basketItem[]) => {
        this.basketStorage = basketStorage;
        this.basketInfo = await this.basket.calculateBasketInfo();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });

    this.loadBasket();
  }

  remove(item: any, index: number) {
    item.amount = parseFloat(item.amount || 1);
    item.amount--;

    if ((!item.variations || !item.variations.length) && !item.amount) {
      this.basketStorage = this.basketStorage.filter(_item => {
        return _item.id !== item.id;
      });
    } else
      if (!item.amount) {
        this.basketStorage = this.basketStorage.filter((_item: any, _index: number) => {
          console.log(_index, index);
          return _index !== index;
        });
      }

    this.basket.setBasket(this.basketStorage)
      .then(async () => {
        this.basketInfo = await this.basket.calculateBasketInfo();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  shop() {
    this.navCtrl.navigateRoot('/dashboard');
    this.dismiss(null, 'done');
  }

  validateCoupon(response: any) {
    if (response.data.use && (!response.data.coupon || !response.data.coupon.id)) {
      this.events.publish('error', 'Der Gutschein-Code wurde nicht gefunden.');
    } else {
      this.events.publish('appcms:alert', {
        title: 'Hinzugefügt',
        message: 'Der Gutschein-Code wurde eingelöst.',
      });
    }
  }

  viewItem(item: any, index: number) {
    this.basket.setEditItemIndex(index);
    this.navCtrl.navigateForward('/details/' + item.id);
    this.dismiss(null, 'done');
  }

}