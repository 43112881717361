import { Component, HostListener, Input, OnInit } from '@angular/core';

import { AnimationItem } from 'lottie-web';

import { ConfigService } from "src/app/services/core/config.service";
import { IntroService } from 'src/app/services/utils/intro.service';
import { ViewService } from "src/app/services/core/view.service";

@Component({
  selector: 'pipeline-intro-card',
  templateUrl: './intro-card.component.html',
  styleUrls: ['./intro-card.component.scss'],
})
export class IntroCardComponent implements OnInit {
  @Input() view: any;

  appConfig: pipelineAppConfig;

  @Input() lottieOptions: lottieOptions = {};

  @Input() splineOptions: splineOptions = {};

  constructor(
    private configService: ConfigService,
    private introService: IntroService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  animationCreated(animationItem: AnimationItem): void {
  }

  async calcHidden() {
    this.view.hidden = await this.introService.isIntroCardHidden(this.view.uid);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  configReady() {

  }

  dataReady() {

  }

  destroy(event: any|null = null) {
    console.warn('destroy', event);
  }

  dismiss(data: any|null = null, role: string | null = 'dismiss') {
    this.introService.isIntroCardHidden(this.view.uid, true);
    this.view.hidden = true;
  }

  domLoaded() {

  }

  error(event: any|null = null) {
    console.warn('error', event);
  }

  loadLottie() {

    if (!this.view.lottieSrc || !this.view.lottieSrc.length) {
      return false;
    }

    this.lottieOptions.render_path = this.view.lottieSrc;

    if (!!this.lottieOptions.render_path) {
      this.updateLottieAnimation();
    }

  }

  loadSpline() {

    if (!this.view.splineSrc || !this.view.splineSrc.length) {
      return false;
    }

    this.lottieOptions.render_path = this.view.splineSrc;

    if (!!this.lottieOptions.render_path) {
      this.updateSplineAnimation();
    }

  }

  ngOnChanges() {
    this.calcHidden();
  }

  ngOnInit() {
    this.calcHidden();
    this.calcViewVars();

    this.loadLottie();
    this.loadSpline();
  }

  @HostListener('window:resize')
  onResize() {
    this.calcViewVars();
  }

  updateLottieAnimation(): void {
    this.lottieOptions = {
      ...this.lottieOptions,
      path: this.lottieOptions.render_path,
    };
  }

  updateSplineAnimation() {
    this.splineOptions = {
      ...this.splineOptions,
      path: this.splineOptions.render_path,
    };
  }

}