import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { Color, ColorPickerControl } from '@iplab/ngx-color-picker';

@Component({
  selector: 'pipeline-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ColorPickerComponent implements OnInit {

  @Input() key: string;
  @Input() parentKey: string;

  @Input()
  public set color(color: string) {

    if (!!color && !!color.length && (`${color}`.length < 7)) {
      color = `${color}FF`;
    }

    this.view.color = `${color || ''}`;

    this.colorControl.setValueFrom(this.view.color);
  }

  @Output() colorChange = new EventEmitter();

  public colorControl = new ColorPickerControl();

  view: any = {
    visible: false,
  };

  constructor(

  ) {
  }

  ngOnInit() {

    this.colorControl.valueChanges.subscribe((value: Color) => {
      let color: string = this.rgbaToHex(`${value.toRgbaString()}`);

      if (`${color}`.length < 7) {
        color = `${color}FF`;
      }

      if (color === 'FF') {
        color = 'FFFFFFFF';
      }

      this.view.color = color;
      this.colorChange.emit(`${this.view.color}`);
    });
  }

  onColorChanged(event: any|null = null) {
    console.log('onColorChanged', event);
  }

  rgbaToHex(rgba: string) {
    let parts: string[] = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(.*?)\)?$/);
    delete (parts[0]);

    for (let i = 1; i <= 3; ++i) {
      parts[i] = parseInt(parts[i]).toString(16);
      if (parts[i].length == 1) parts[i] = '0' + parts[i];
    }

    if (parts[4]) {
      parts[4] = Math.round(parseFloat(parts[4]) * 255).toString(16);
      if (parts[4].length == 1) parts[4] = '0' + parts[4];
    } else {
      parts[4] = 'FF';
    }

    let hex: string = parts.join('');

    if (hex === 'FF') {
      hex = 'FFFFFFFF';
    }

    return '#' + hex;
  }

  viewPicker() {
    this.view.visible = !this.view.visible;
  }

}
