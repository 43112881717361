import { Injectable } from '@angular/core';

import { LoadingService } from '../utils/loading.service';

import { CreateMLCEngine } from "@mlc-ai/web-llm";

@Injectable({
    providedIn: 'root'
})
export class AiLoaderService {

    engine: any;

    modelName: string = "Llama-3.1-8B-Instruct-q4f32_1-MLC";

    constructor(
        private loading: LoadingService,
    ) {
    }

    completions(prompt: string, messages: aiExecutionHistoryItem[] | null = null, blStream: boolean = false, params: any = {}) {
        return new Promise(async (resolve, reject) => {
            try {
                console.log('ai-loader: completions: prompt', prompt);
                console.log('ai-loader: completions: messages', messages);
                console.log('ai-loader: completions: params', params);

                const engine: any = await this.getEngine();

                messages = messages || [];

                messages.push({
                    role: 'user',
                    content: prompt,
                });

                let options: any = {
                    messages: messages,
                };

                if (!options.hasOwnProperty('temperature')) {
                    options.temperature = 1;
                }

                let reply: any, output: string | null;

                if (!!blStream) {

                    options = Object.assign(options, {
                        stream: true,
                        stream_options: { include_usage: true },
                    });

                    const chunks = await engine.chat.completions.create(options);
                    let partialReply: string = "";
                    let iPartIndex: number = 0;

                    for await (const chunk of chunks) {
                        partialReply += `${chunk.choices[0]?.delta.content || ''}`;

                        // stream partial result (currently only supported in local mode)
                        if (!!params.hasOwnProperty('onPartialResult')) {
                            params.onPartialResult(partialReply, iPartIndex);
                        }

                        if (chunk.usage) {
                            console.log(chunk.usage); // only last chunk has usage
                        }

                        iPartIndex++;
                    }

                    reply = await engine.getMessage();

                    output = reply;
                } else {
                    reply = await engine.chat.completions.create(options);
                    output = reply?.choices[0]?.message?.content;
                }

                const response: any = Object.assign(reply, {
                    output: output,
                    success: !!reply,
                });

                resolve(response);
            } catch (e) {
                reject(e);
            }
        })
    }

    getEngine() {
        return new Promise(async (resolve, reject) => {
            if (!this.engine) {

                const loadingOptions: loadingOptions = {
                    events: {
                        onDidDismiss: (response: any) => {
                            console.log('ai-loader: onDidDismiss', response);
                        },
                        onWillDismiss: (response: any) => {
                            console.log('ai-loader: onWillDismiss', response);
                        },
                    },
                    props: {
                        headline: 'ai_loader_loading_model_headline',
                        text: 'ai_loader_init_step_0',
                    },
                };

                const loadingModal: any = await this.loading.present(loadingOptions);

                const initProgressCallback = (initProgress: any) => {
                    if (!!initProgress.text) {
                        loadingModal.updateConfig({
                            icon: `${initProgress.progress === 100 ? 'checkmark-outline' : 'hourglass-outline'}`,
                            text: `${initProgress.text || ''}`,
                        });
                    }
                };

                const selectedModel = this.getSelectedModel();

                try {

                    this.engine = await CreateMLCEngine(
                        selectedModel,
                        { initProgressCallback: initProgressCallback }, // engineConfig
                    );

                    loadingModal.dismiss();

                    resolve(this.engine);
                } catch (e) {
                    console.log('dismiss modal (b)', loadingModal);
                    reject(e);
                }
            } else {
                resolve(this.engine);
            }
        });
    }

    getSelectedModel() {
        return this.modelName;
    }

    load() {
        return new Promise(async (resolve, reject) => {
            try {
                const engine: any = await this.getEngine();
                console.log('ai-loader: llm: engine', engine);

                resolve({
                    engine: engine,
                });
            } catch (e: any) {
                reject(e);
            }
        });
    }

    setSelectedModel(modelName: string) {
        this.modelName = modelName;
        return this;
    }

}