import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { CdkDragEnd } from '@angular/cdk/drag-drop';

import { EventsService } from "src/app/services/core/events.service";
import { FontsService } from 'src/app/services/utils/fonts.service';
import { TemplateEditorService } from 'src/app/services/media/template-editor.service';
import { TemplatesService } from 'src/app/services/media/templates.service';

import { proxyUrl } from 'src/config/variables';

@Component({
  selector: 'pipeline-media-template-preview',
  templateUrl: './media-template-preview.component.html',
  styleUrls: ['./media-template-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MediaTemplatePreviewComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() template: mediaTemplate;
  @Input() view: any;

  @ViewChild('templatePreviewInnerStage', { read: ElementRef }) private templatePreviewInnerStage: ElementRef;

  proxyUrl: string;
  
  runInterval: any;

  constructor(
    private editor: TemplateEditorService,
    private events: EventsService,
    private fonts: FontsService,
    private templates: TemplatesService,
  ) {
  }

  asyncLoadFontByLayer(layer: any) {
    console.log('asyncLoadFontByLayer', layer);

    if (!!layer && !!layer.settings && !!layer.settings.font_data) {
      this.fonts.loadFontToDocument(layer.settings.font_data);
    }
  }

  calcCurrentLayerStyle() {
    //this.zone.run(() => {

    if (!this.view.sizes) {
      this.view.sizes = this.templates.getScreenSizesList();
    }

    if (!this.view.sizes[this.template.config.aspect_ratio]) {
      console.warn('template-preview (3): size by aspect ratio not detected', this.template);
      return false;
    }

    if (!this.template.config || !this.template.config.aspect_ratios || !this.template.config.aspect_ratios[this.template.config.aspect_ratio]) {
      console.warn('template preview has no layers');
      return false;
    }


    let templateWrapperString: string, layerId: string, layerElementSelector: string, layerElement: HTMLElement | null;
    let scriptElement: any, scriptElementId: string, styleElement: any, styleElementId: string, existing: any;

    this.template.config.aspect_ratios[this.template.config.aspect_ratio].forEach((layer: any, index: number) => {
      templateWrapperString = (!!this.template.uid ? `.stage-template-uid-${this.template.uid} ` : '');
      layerId = (layer && layer.settings && layer.settings.id ? layer.settings.id : `stage-layer-component-${index}`);
      layerElementSelector = `${templateWrapperString}#${layerId}`;
      layerElement = this.templatePreviewInnerStage.nativeElement.querySelector(layerElementSelector);

      switch (layer.type) {
        case 'lottie':
          let src: string | null = null;

          layer.settings.lottieOptions = layer.settings.lottieOptions || {
            autoplay: !!layer.settings.autoplay,
            loop: !!layer.settings.loop,
            renderer: 'svg',
          };

          if (!!layer.settings.lottie_use_proxy) {
            src = `${this.proxyUrl}${layer.settings.lottie_src}`;
          } else {
            src = layer.settings.lottie_src;
          }

          if (!!layer.settings.lottie_src) {
            layer.settings.lottieOptions = {
              ...layer.settings.lottieOptions,
              path: src,
            };
          }

          if (layer.settings.hasOwnProperty('loop')) {
            layer.settings.lottieOptions = {
              ...layer.settings.lottieOptions,
              loop: !!layer.settings.loop,
            };
          }

          break;
        case 'text':
          this.asyncLoadFontByLayer(layer);
          break;
      }

      if (!!layer.settings.css) {
        styleElementId = `style-element-${this.template.uid || 0}-${index}`;
        existing = document.querySelector(`style#${styleElementId}`);

        if (!!existing) {
          existing.innerHTML = this.editor.isolateLayerCSS(layer, this.template);
        } else
          if (!!layerElement) {
            styleElement = document.createElement("style");
            styleElement.classList.add('dynamic-style-element');
            styleElement.id = styleElementId;
            styleElement.type = "text/css";
            styleElement.innerHTML = this.editor.isolateLayerCSS(layer, this.template);

            document.body.appendChild(styleElement);
          }
      }

      if (!!layer.settings.js) {
        scriptElementId = `script-element-${this.template.uid || 0}-${index}`;
        existing = this.templatePreviewInnerStage.nativeElement.querySelector(`script#${scriptElementId}`);

        if (!!existing) {
          existing.innerHTML = `${layer.settings.js}`.replace('let element =', 'var element =');
        } else
          if (!!layerElement) {
            try {
              scriptElement = document.createElement("script");
              scriptElement.classList.add('dynamic-script-element');
              scriptElement.id = scriptElementId;
              scriptElement.type = "text/javascript";
              scriptElement.innerHTML = `${layer.settings.js}`;

              scriptElement.onerror = (error: any) => {
                console.warn('script loading failed (2)', error);
              };

              document.body.appendChild(scriptElement);
            } catch (e) {
              console.warn('script loading failed (1)', e);
            }
          }
      }

      this.template.config.aspect_ratios[this.template.config.aspect_ratio][index].style = this.templates.calculateLayerStyle(layer, null, this.template);

      //this.detectChanges();
    });

    //});
  }

  calcFormattedStyle() {
    try {
      if (!this.template && !!this.template.config && !!this.template.config.aspect_ratios && !!this.template.config.aspect_ratios[this.template.config.aspect_ratio]) {
        this.template.config.aspect_ratios[this.template.config.aspect_ratio].forEach((layer: any, index: number) => {
          const formattedStyle: string = `${layer.style}`;
          layer.formatted_style = formattedStyle;
          this.template.config.aspect_ratios[this.template.config.aspect_ratio][index] = layer;
        });
      }
    } catch (e) {
      console.warn('fixing layer style failed', e);
    }
  }

  calcStageSize() {
    const sizes: number[] = (!!this.view && !!this.view.sizes ? this.view.sizes[this.template.config.aspect_ratio] : null);

    if (!sizes) {
      console.warn('template-preview (1): size by aspect ratio not detected', this.template);
      return false;
    }

    if (!!sizes[0] && !!sizes[1] && (!!this.view.maxWidth || !!this.view.maxHeight)) {
      if (!!this.view.maxWidth && (sizes[0] > this.view.maxWidth)) {
        // scale down using width
        this.view.scaleFactor = ((100 / sizes[0]) * this.view.maxWidth) / 100;
      } else
        if (!!this.view.maxHeight && (sizes[1] > this.view.maxHeight)) {
          // scale down using height
          this.view.scaleFactor = ((100 / sizes[1]) * this.view.maxHeight) / 100;
        }
    }
  }

  calcTicks() {

    if (!this.template.config.timeline || !this.template.config.timeline.end) {
      return false;
    }

    let iCurrent: number = 0,
      ticks: any[] = [];

    while (iCurrent < this.template.config.timeline.end) {
      iCurrent++;

      ticks.push({
        value: iCurrent,
      });
    }

    this.view.ticks = ticks;
  }

  calcTransformStyle() {
    if (!!this.view.scaleFactor) {
      this.view.transformStyle = `scale(${this.view.scaleFactor})`;

      //this.view.stageHeight = parseInt(`${this.view.sizes[this.template.config.aspect_ratio][1] * this.view.scaleFactor}`) + 'px';
      //this.view.stageWidth = parseInt(`${this.view.sizes[this.template.config.aspect_ratio][0] * this.view.scaleFactor}`) + 'px';
    }
  }

  calcViewVars() {
    this.calcTicks();

    if (!!this.template && !!this.template.config && !!this.template.config.aspect_ratios && !!this.template.config.aspect_ratios[this.template.config.aspect_ratio] && !!this.template.config.aspect_ratios[this.template.config.aspect_ratio].length) {
      this.template.config.aspect_ratios[this.template.config.aspect_ratio].forEach((layer: any, index: number) => {
        layer.active = (index === this.view.selectedLayerIndex);
      });
    }

    this.calcTransformStyle();
    this.calcCurrentLayerStyle();
    this.calcFormattedStyle();

    this.detectChanges();
  }

  calcVisibleLayers() {
    this.detectChanges();
  }

  detectChanges() {
    /*
    try {
      this.changeDetectorRef.detectChanges();
    } catch(e) {
      console.warn('updating template preview failed', e);
    }
    */
  }

  dragEnd(event: CdkDragEnd, layer: any, index: number) {
    this.view.isDragging = false;

    if (!this.view.sizes[this.template.config.aspect_ratio]) {
      console.warn('template-preview (2): size by aspect ratio not detected', this.template);
      return false;
    }

    const iHeight: number = (100 / this.view.sizes[this.template.config.aspect_ratio][1]),
      iWidth: number = (100 / this.view.sizes[this.template.config.aspect_ratio][0]),
      x: any = (iHeight * (event.distance.x * this.view.scaleFactor)) || 0,
      y: any = (iWidth * (event.distance.y * this.view.scaleFactor)) || 0;

    if (!!this.template.config.aspect_ratios[this.template.config.aspect_ratio][index] && !!this.template.config.aspect_ratios[this.template.config.aspect_ratio][index].settings) {
      this.template.config.aspect_ratios[this.template.config.aspect_ratio][index].settings.x += x;
      this.template.config.aspect_ratios[this.template.config.aspect_ratio][index].settings.y += y;

      this.events.publish('media:template:preview:layer:dragEnd', {
        index: index,
        layer: this.template.config.aspect_ratios[this.template.config.aspect_ratio][index],
        view: this.view,
      });

      this.calcCurrentLayerStyle();
    }

  }

  editorResized(data: any) {
    this.detectChanges();
  }

  initEvents() {
    this.view.events = {};

    this.view.events.mediaTemplatePreviewLayerResized = this.events.subscribe('media:template:preview:layer:resized', (data: any) => {
      this.template.config.aspect_ratios[this.template.config.aspect_ratio][data.index] = data.layer;
      this.template.config.aspect_ratios[this.template.config.aspect_ratio][data.index] = data.layer;

      this.calcCurrentLayerStyle();
    });

    this.view.events.mediaTemplatePreviewPause = this.events.subscribe('media:template:preview:pause', (data: any) => {
      let template = (!!data && !!data.template ? data.template : null);

      if (this.templates.isCurrent(template, this.template)) {
        this.pauseSet();
      }
    });

    this.view.events.mediaTemplatePreviewResized = this.events.subscribe('media:template:preview:resized', (data) => {
      let template = (!!data && !!data.template ? data.template : null);

      if (this.templates.isCurrent(template, this.template)) {
        this.editorResized(data);
      }
    });

    this.view.events.mediaTemplatePreviewRun = this.events.subscribe('media:template:preview:run', (data: any) => {
      let template = (!!data && !!data.template ? data.template : null);

      if (this.templates.isCurrent(template, this.template)) {
        this.runSet();
      }
    });

    this.view.events.mediaTemplatePreviewStop = this.events.subscribe('media:template:preview:stop', () => {
      this.stopSet();
    });

    this.view.events.mediaTemplatePreviewUpdated = this.events.subscribe('media:template:preview:updated', (data: any) => {
      let template = (!!data && !!data.template ? data.template : null);

      if (this.templates.isCurrent(template, this.template)) {
        template.config = template.config || {};
        template.config._meta = template.config._meta || {};

        this.template = template;

        //if(!!data.view) {
        //  this.view = data.view;
        //}

        this.calcStageSize();
        this.calcViewVars();
      }
    });

  }

  ngAfterViewInit() {
    this.calcStageSize();
    this.calcViewVars();

    if (!!this.view.editable) {
    }

    if (!!this.view.running) {
      this.runSet();
    }
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.template.config = this.template.config || {};
    this.template.config.aspect_ratio = this.template.config.aspect_ratio || '1x1';
    this.view.sizes = this.view.sizes || this.templates.getScreenSizesList();

    this.initEvents();
  }

  pauseSet() {
    this.setRunning(false);

    try {
      clearInterval(this.runInterval);
    } catch (e) {

    }
  }

  runSet() {

    if (!!this.view.running) {
      console.warn('preview: already running, doing nothing');
      return false;
    }

    this.stopSet();
    this.setRunning(true);

    if (!this.view.ticks || !this.view.ticks.length) {
      console.warn('no ticks, skipping', this.view);
      return false;
    }

    this.runInterval = setInterval(() => {

      if (!this.view.ticks || !this.view.ticks.length || !this.template.config.timeline) {
        console.warn('no ticks or timeline, stopping', this.view);
        this.stopSet();
        return false;
      }

      if (!!this.view.infinite && (this.view.ticks.length <= this.template.config.timeline.current)) {
        this.template.config.timeline.current = 0;
      }

      if (this.view.ticks.length > this.template.config.timeline.current) {
        //this.zone.run(() => {
        this.template.config.timeline.current++;

        this.events.publish('media:template:preview:timeline:current:updated', {
          template: this.template,
          current: this.template.config.timeline.current,
        });

        this.calcVisibleLayers();
        //});
      } else {
        this.stopSet();
      }
    }, 1000);
  }

  setRunning(bl: boolean) {
    //this.zone.run(() => {
    this.view.running = !!bl;
    this.events.publish('media:template:preview:running', !!bl);

    this.detectChanges();
    //});
  }

  stopSet() {

    try {
      clearInterval(this.runInterval);
    } catch (e) {

    }

    //this.zone.run(() => {
    this.setRunning(false);

    if (!!this.template.config.timeline) {
      this.template.config.timeline.current = 0;

      if (!!this.template.config && !!this.template.config.timeline) {
        this.template.config.timeline.current = this.template.config.timeline.current;
      }

      this.events.publish('media:template:preview:timeline:current:updated', {
        template: this.template,
        current: this.template.config.timeline.current,
      });

    }

    if (!!this.template.config.aspect_ratios[this.template.config.aspect_ratio] && !!this.template.config.aspect_ratios[this.template.config.aspect_ratio].length) {
      this.template.config.aspect_ratios[this.template.config.aspect_ratio].forEach((layer: any) => {
        layer.visible = true;
        layer.hidden = false;
      });
    }

    //this.detectChanges();
    //});
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}