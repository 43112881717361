import { Component, OnInit } from '@angular/core';

import { CouponsService } from 'src/app/services/extensions/coupons.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { EventsService } from "src/app/services/core/events.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.page.html',
  styleUrls: ['./coupon.page.scss'],
})
export class CouponPage implements OnInit {

  coupon: coupon;

  view: any = {
    cities: [],
    mode: 'create',
  };

  constructor(
    private coupons: CouponsService,
    private events: EventsService,
    private modalService: ModalService,
    private media: MediaextendService,
    private viewService: ViewService,
  ) {
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  create() {
    this.coupons.create(this.parseCoupon(this.coupon))
    .then(() => {
      this.dismiss(null, 'done');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  dismiss(data: any|null = null, role: string|null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  parseCoupon(_coupon: coupon) {
    let coupon: coupon = JSON.parse(JSON.stringify(_coupon));
    
    coupon.end_date = coupon.end_date.split('+')[0];
    coupon.start_date = coupon.start_date.split('+')[0];

    return coupon;
  }

  uploadPhoto() {
    
    this.media.applyFromWeb(null, {
    }).then((response: any) => {
      let imageUrl: string|null = (typeof response === 'string' ? response : null);
      
      if(!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
        imageUrl = response.items[0].thumbnail;
      }
      
      this.coupon.photo = imageUrl;
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  update() {
    this.coupons.update(this.parseCoupon(this.coupon))
    .then(() => {
      this.dismiss(null, 'done');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

}
