import { Component, OnInit } from '@angular/core';

import { WoocommerceextendService } from 'src/app/services/ecommerce/woocommerceextend.service';
import { ConfigurationService } from 'src/app/services/core/configuration.service';
import { ModalService } from "src/app/services/core/modal.service";

@Component({
  selector: 'app-configure-product',
  templateUrl: './configure-product.page.html',
  styleUrls: ['./configure-product.page.scss'],
})
export class ConfigureProductPage implements OnInit {

  product: any;

  constructor(
    private configurator: ConfigurationService,
    private modalService: ModalService,
    private woocommerce: WoocommerceextendService,
  ) {
  }

  addToProduct(product: any) {
    if(this.canAdd()) {
      product.amount++;
    }
    this.recalculate();
  }

  canAdd() {
    let blCanAdd = true;
    return blCanAdd;
  }

  /**
   * Configures product with sub products
   * @param product 
   */
  configure(product: any) {
    this.product.items.forEach((_productItem: any) => {
      _productItem.configureMode = false;
      _productItem.configItems.forEach((configItem: any) => {
        configItem.configureMode = false;
      });
    });
    product.configureMode = !product.configureMode;
  }

  /**
   * Configure sub item of a product
   * @param productItem 
   */
  configureItem(productItem: any, iRelated, iItem: any) {
    this.product.items.forEach((_productItem: any) => {
      _productItem.configItems.forEach((configItem: any) => {
        if(productItem.title != configItem.title) {
          configItem.configureMode = false;
        }
      });
    });
    productItem.configureMode = !productItem.configureMode;
  }

  async dismiss(data: any|null = null, role: string|null = 'dismiss') {
    this.product.configured = true;
    (await this.modalService).dismiss({
      product: this.product,
    });
  }

  ionViewWillEnter() {
    
    if(!this.product.configured) {
      this.product.items = this.woocommerce.parseDescriptionToProductItems(this.product);
      let configSettings = this.configurator.getConfiguratorSettings(this.product);

      if (configSettings.items) {
        this.product.items = configSettings.items;
      }
      if (configSettings._items) {
        this.product._items = configSettings._items;
      }
    }
  }

  ngOnInit() {
  }

  recalculate() {
    let configSettings = this.configurator.getConfiguratorSettings(this.product);

    if(configSettings._items) {
      this.product._items = configSettings._items;
    }

    if(configSettings.price) {
      this.product.price = configSettings.price;
    }

    if(configSettings._price) {
      this.product._price = configSettings._price;
    }

    if(configSettings.price_html) {
      this.product.price_html = configSettings.price_html;
    }

  }

  removeFromProduct(product: any) {
    if(product.amount) {
      product.amount--;
    }
    this.recalculate();
  }

  saveConfiguration(product: any) {
    product.configureMode = false;  
  }

  updated() {
    setTimeout(() => {
      let metaData: any = [], iNote: number = 0, args: string;
      this.product.items.forEach((item: any) => {
        iNote++;
        let key = 'configuration_note_' + iNote;
        let value = item.amount + 'x ' + item.name;
        metaData.push({
          key: key,
          value: value
        });
        item.configItems.forEach((configItem: any) => {
          iNote++;
          key = 'configuration_note_' + iNote;
          args = '';
          configItem.options.forEach((option: any) => {
            if(option.checked) {
              if(args.length) {
                args += ', ';
              }
              args += option.title;
            }
          });
          if(args.length) {
            value = configItem.title + ': ' + args;
            metaData.push({
              key: key,
              value: value
            });
          }
        });
      });
      this.product.meta_data = metaData;
    }, 100);
  }

}