import { Component, HostListener, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { AiWorkerService } from 'src/app/services/ai/ai-worker.service';
import { EventsService } from 'src/app/services/core/events.service';
import { IconPickerService } from 'src/app/services/utils/icon-picker.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ToolsService } from "src/app/services/utils/tools.service";

@Component({
  selector: 'app-ai-shortcode',
  templateUrl: './ai-shortcode.page.html',
  styleUrls: ['./ai-shortcode.page.scss'],
})
export class AiShortcodePage implements OnInit {

  ai: any;

  shortcode: aiShortcode = {
    icon: 'extension-puzzle-outline',
    input: '',
    name: '',
    type: 'user',
  };

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'ai_shortcode_top_card',
      text: 'ai_shortcode_top_card_text',
      title: 'ai_shortcode_top_card_title',
    },
    route: 'ai/shortcode',
    showMenuButton: true,
    tab: 'custom',
    title: 'create_ai_shortcode',
  };

  constructor(
    private aiWorker: AiWorkerService,

    private events: EventsService,
    private iconPicker: IconPickerService,
    private modalService: ModalService,
    private navParams: NavParams,
    private toolsService: ToolsService,
  ) {
    this.ai = this.navParams.get('ai');

    this.view.promptFunctionActions = this.ai.getPromptFunctionActions();
    this.view.promptTypes = this.ai.getPromptTypes();

    const shortcode: aiShortcode | null = this.navParams.get('shortcode');
    console.log('provided shortcode', shortcode);

    if (!!shortcode) {
      this.shortcode = shortcode;
    }
  }

  addVariable() {

    if (!this.view.variables) {
      this.view.variables = [];
    }

    this.view.variables.push({
      action: '',
      uid: '',
    });

  }

  calcInputVars() {
    setTimeout(() => {
      var varsInShortcodeInput = [...new Set(`${this.shortcode.input}`.match(/\{(.*?)\}/g))];

      if (!!varsInShortcodeInput) {
        this.view.variables = varsInShortcodeInput.map((input: any, index: number) => {
          input = input.replace(/{/g, '').replace(/}/g, '');

          return {
            action: 'auto',
            input_variable: input,
            name: input,
            output_variable: '',
            required: true,
            uid: input,
            value: (this.view.variables && !!this.view.variables[index] ? this.view.variables[index].value : ''),
          };
        });
      }
    });
  }

  calcViewVars() {
    this.view.isDesktop = this.toolsService.isDesktop();
    this.view.isModal = this.modalService.isModal();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);

    this.calcInputVars();
  }

  chooseIcon() {
    this.iconPicker.pick()
      .then((response: any) => {
        if (!!response && !!response.icon) {
          this.shortcode.icon = response.icon;
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  create() {
    const methodName: string = (!!this.shortcode.uid ? 'updateShortcode' : 'saveShortcode');
    console.log('methodName', methodName);

    this.aiWorker[methodName](this.shortcode)
      .then((response: any) => {
        console.log('response', response);
        this.dismiss(response, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteVariable(iVariable: number) {
    this.view.variables = this.view.variables.filter((_var: any, _i: number) => {
      return iVariable !== _i;
    });
  }

  dismiss(data: any|null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  ngOnInit() {
    this.calcViewVars();
  }

  @HostListener('window:resize')
  onResize() {
    this.view.isDesktop = this.toolsService.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  onShortcodeInputChanged(event: any|null = null) {
    this.calcViewVars();
  }

  onShortcodePromptTypeChanged() {

  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}