import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  _detailItem: calendarEvent|null;

  eventsStorage: any = {};

  constructor(
    //private log: LogfileService,
  ) {
  }

  detailItem(item: calendarEvent|null = null) {

    if(item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  publish(key: string, data: any|null = null) {
    this.eventsStorage[key] = this.eventsStorage[key] || new Subject<any>();
    this.eventsStorage[key].next(data);
  }

  stop(eventsList: any[]|null = null) {

    if(!eventsList) {
      return false;
    }

    let _event: any, keys: string[] = Object.keys(eventsList);

    keys.forEach((eventKey: string) => {
      try {
        _event = eventsList[eventKey];
        _event.unsubscribe();
      } catch(e) {
        console.warn('> unsubscribing event failed', _event, e);
      }
    });
  }

  subscribe(key: string, callback: any|null = null): Subject<any> {
    this.eventsStorage[key] = this.eventsStorage[key] || new Subject<any>();
    return this.eventsStorage[key].subscribe((data: any) => {
      if(callback) {
        callback(data);
      }
    });
  }

}
