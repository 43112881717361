<!-- Open button -->
<ion-button [fill]="fill" [size]="size" [color]="color" (click)="openModal($event)">
    <ion-icon name="sparkles-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap" [hidden]="!view.isDesktop">
        <h5 [innerHTML]="'ai_settings'|translate"></h5>
    </ion-label>
</ion-button>

<!-- AI settings popover -->
<ion-popover #aiSettingsPopover [isOpen]="isAiSettingsPopoverOpen" (didDismiss)="isAiSettingsPopoverOpen = false">
    <ng-template>
        <pipeline-ai-settings-card [(cards)]="cards" [(config)]="config" [options]="options"
            [(view)]="view"></pipeline-ai-settings-card>
    </ng-template>
</ion-popover>