import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

import { BrowserService } from 'src/app/services/utils/browser.service';
import { EventsService } from "src/app/services/core/events.service";
import { PipelineService } from 'src/app/services/pipeline/pipeline.service';
import { RatingsService } from 'src/app/services/pipeline/ratings.service';
import { UserService } from "src/app/services/core/user.service";
import { PostsService } from 'src/app/services/posts/posts.service';

import { PostInformationPage } from '../post/post-information/post-information.page';

@Component({
  selector: 'app-card-options',
  templateUrl: './card-options.page.html',
  styleUrls: ['./card-options.page.scss'],
})
export class CardOptionsPage implements OnInit {

  col: any;

  event: any;

  user: user;

  constructor(
    private browser: BrowserService,
    private events: EventsService,
    private navParams: NavParams,
    private pipeline: PipelineService,
    private popoverCtrl: PopoverController,
    private posts: PostsService,
    private ratings: RatingsService,
    public userService: UserService,
  ) {
    this.col = this.navParams.get('col');
    this.event = this.navParams.get('event');
    this.user = this.userService.getUser() || {};
  }

  delete(event: any|null = null) {
    this.posts.deletePost(this.col.uid)
    .then(() => {
      this.popoverCtrl.dismiss();
      this.events.publish('home:refresh');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  async information(event: any|null = null) {
    this.popoverCtrl.dismiss();
    let informationPopup = await this.popoverCtrl.create({
      animated: true,
      component: PostInformationPage,
      componentProps: {
        post: this.col,
      },
      cssClass: 'postInformationPopover',
      event: this.event,
    });
    informationPopup.present();
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};
  }

  ngOnInit() {
  }

  rate(event: any|null = null) {
    this.popoverCtrl.dismiss();
    this.ratings.rate(this.col as post)
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  move(event: any|null = null) {
    this.popoverCtrl.dismiss();
    this.pipeline.move(this.col.uid)
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  openExternal(event: any|null = null) {
    this.popoverCtrl.dismiss();
    this.browser.create(this.col.url);
  }

  openProfile(event: any|null = null) {
    this.popoverCtrl.dismiss();
  }

}
