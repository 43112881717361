import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { BrowserService } from "src/app/services/utils/browser.service";
import { ClipboardService } from 'src/app/services/utils/clipboard.service';
import { EventsService } from "src/app/services/core/events.service";
import { LinkshortenerService } from 'src/app/services/utils/linkshortener.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Component({
  selector: 'pipeline-shortlink-generator',
  templateUrl: './shortlink-generator.component.html',
  styleUrls: ['./shortlink-generator.component.scss']
})
export class ShortlinkGeneratorComponent implements OnDestroy, OnInit {
  @Input() config: any = {};
  @Input() disabled: boolean;
  @Input() link: any = {};
  @Input() url: string;

  @Output() onChanged = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  item: any;

  view: any = {
    smart_link_host: 'https://open.getgenius.app/',
  };

  constructor(
    private browser: BrowserService,
    private clipboard: ClipboardService,
    private events: EventsService,
    public shortlinks: LinkshortenerService,
    private tools: ToolsService,
  ) {
  }

  public copySmartLink() {

    if (!this.link || !this.link.short_url) {
      return false;
    }

    this.clipboard.copyText(`${this.link.short_url}`);
  }

  public invalidateSmartLink() {
    this.link = null;
  }

  ngOnDestroy() {

  }

  ngOnInit() {
  }

  async onUrlChanged(event: any|null = null) {
    const validate: any = this.tools.validateUrl(this.url);

    this.view.isValidWebsiteUrl = validate.success;

    if (!this.view.isValidWebsiteUrl) {
      //this.events.publish('error', 'error_url_invalid');
      return false;
    }

    try {
      const lookup: any = await this.shortlinks.lookup(this.url)

      if (!!lookup && !!lookup.link && !!lookup.link.uid) {
        this.link = lookup.link;
      }

    } catch (e) {
      this.events.publish('error', e);
    }
  }

  public openSmartLink() {

    if (!this.link || !this.link.short_url) {
      return false;
    }

    this.browser.create(`${this.link.short_url}`);
  }

  public requestSmartLink() {
    this.runSearch();
  }

  runSearch(event: any|null = null) {
    this.onUrlChanged();
  }

}